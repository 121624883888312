import React, { useState, useEffect } from 'react';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listNotes, notesByDate, notesByEventDate } from './graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import { API, Storage } from 'aws-amplify';
import Row from './components/Row.js';
import EloiseImage from './components/Eloise.gif';
import Background from './components/background.png';
import AddEntry from './pages/AddEntry.js';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import Nav from './components/Nav';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

Amplify.configure(awsconfig);

// >>New - Configuring Auth Module
Auth.configure(awsconfig);

const initialFormState = { name: '', description: '', type: 'Note' }

function App() {
  const [notes, setNotes] = useState([]);

  useEffect(() => {    
    fetchNotes();    
  }, []);

  async function fetchNotes() {

    const apiData = await API.graphql({
      query: notesByEventDate,
      variables: {
        type: 'Note',
        sortDirection: 'DESC'
      }      
    });

    const notesFromAPI = apiData.data.notesByEventDate.items;
    await Promise.all(
      notesFromAPI.map(async note => {
      if (note.image) {
        const image = await Storage.get(note.image);        
        note.image = image;        
      } else if(note.video){
        const video = await Storage.get(note.video);
        note.video = video;
      } else if(note.icon){
        const icon = await Storage.get(note.icon);
        note.icon = icon;
      }
      return note;
    }))

    setNotes(apiData.data.notesByEventDate.items);
    
    AOS.init({      
      once: true
      ,offset: 600
    })
  }

  return (
  <Router>
    <div className="App">
      <Nav />
      <div style={{width: '100%', height: '100vh', backgroundColor: '#426d49'}}>
        <img style={{display:'block', margin:'auto', height:'300px'}} src={EloiseImage} alt="description of gif" />
        <div style={{  position: "absolute", left: "50%", bottom: "0px", transform: "translateX(-50%)"}}>
          <p style={{display:'block', margin: "auto", textAlign: "center", color: '#F5F7F5'}}>Scroll Down</p>
          <ArrowDownwardIcon style={{display:'block', marginLeft:'auto', marginRight: 'auto', color: 'white'}}/>
        </div>                
      </div> 
      <div>
        <Row entries={notes} />
      </div>          
      <Route path="/addentry" component={AddEntry} />        
    </div>
  </Router>
  );
}

export default App;