import React, { useState, useEffect } from 'react';
import { listNotes, notesByDate } from '../graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from '../graphql/mutations';
import { API, Storage } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { withAuthenticator, AmplifySignOut, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, Authenticator } from 'aws-amplify-react';

Amplify.configure(awsconfig);

// >>New - Configuring Auth Module
Auth.configure(awsconfig);

const MyTheme = {
  signInButtonIcon: { 'display': 'none' },
  googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
}

const initialFormState = { name: '', description: '', type: 'Note' }

function AddEntry() {
    const [notes, setNotes] = useState([]);
    const [formData, setFormData] = useState(initialFormState);

    useEffect(() => {
        fetchNotes();
    }, []);

    async function fetchNotes() {

    const apiData = await API.graphql({
      query: notesByDate,
      variables: {
        type: 'Note',
        sortDirection: 'DESC'
      }      
    });

    const notesFromAPI = apiData.data.notesByDate.items;
    await Promise.all(notesFromAPI.map(async note => {
      if (note.image) {
        const image = await Storage.get(note.image);        
        note.image = image;        
      } else if(note.video){
        const video = await Storage.get(note.video);
        note.video = video;
      }
      return note;
    }))

    setNotes(apiData.data.notesByDate.items);   
  }

  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    if (formData.image) {
      const image = await Storage.get(formData.image);
      formData.video = "";
      formData.image = image;
    } else if(formData.video) {
      const video = await Storage.get(formData.video);
      formData.image = "";
      formData.video = video;
    }
    setNotes([ ...notes, formData ]);
    setFormData(initialFormState);
  }

  async function deleteNote({ id }) {
    const newNotesArray = notes.filter(note => note.id !== id);
    setNotes(newNotesArray);
    await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
  }

  async function onChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);
    fetchNotes();
  }

  async function videoOnChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, video: file.name });
    await Storage.put(file.name, file);
    fetchNotes();
  }

  async function iconOnChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, icon: file.name });
    await Storage.put(file.name, file);
    fetchNotes();
  }



  return (
    <div className="AddEntry">
        <AmplifyAuthenticator>
          <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
          <div className="container">
            <div className="form-group">
                <input
                    onChange={e => setFormData({ ...formData, 'name': e.target.value})}
                    placeholder="Note name"
                    value={formData.name}
                />                
              </div>
              <div className="form-group">
                <textarea
                      onChange={e => setFormData({ ...formData, 'description': e.target.value})}                      
                      placeholder="Note description"
                      value={formData.description}
                      rows={10}
                      cols={10}
                      style={{minWidth:"300px", maxWidth:"400px"}}
                  />            
              </div>
              <div className="form-group">  
                <label>Image</label>
                <input
                    type="file"
                    onChange={onChange}
                />                  
              </div>
              <div className="form-group">                  
                  <label>Video</label>
                    <input 
                        type="file"
                        onChange={videoOnChange}
                    />                  
              </div>
              <div className="form-group">
                <label>Fruit/Icon</label>
                  <input 
                      type="file"
                      onChange={iconOnChange}
                  />                  
              </div>
              <div className="form-group">
                <TextField
                    id="datetime-local"
                    label="When Did this occur"
                    type="datetime-local"                        
                    InputLabelProps={{
                    shrink: true,
                    }}
                    onChange={e => setFormData({ ...formData, 'eventAt': e.target.value})}
                    value={formData.eventAt}
                />          
              </div>
              <div className="form-group">             
                <button onClick={createNote}>Create Note</button>            
              </div>          
            </div>
      <AmplifySignOut />
    </AmplifyAuthenticator>
  </div>
  );
}

export default AddEntry;