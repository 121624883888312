import React from 'react'
import '../css/main.css'

class Nav extends React.Component{
    constructor(props) {
        super(props);
        console.log("In Nav componenet");        
        console.log(this.props);        
    }

    render(){        
        return (
            <nav className="navbar navbar-expand-lg navbar-custom">                                
                <div className="navbar-nav mr-auto">
                    <a className="nav-item nav-link" href="https://www.babylist.com/baby-girl-sablan" target="_blank">baby registry</a>
                </div>
                <ul className="navbar-nav navbar-custom">
                    <li className="nav-item navbar-text">brentonn@gmail.com</li>
                </ul>                
            </nav>
        );
    }
}

export default Nav;