import React from 'react'
import Entry from './Entry.js';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import ReactPlayer from 'react-player'
import {
    Image,
    Text,    
  } from 'rebass'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';  
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';  
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class Row extends React.Component{
    constructor(props) {
        super(props);
        console.log("In Row componenet");        
        console.log(this.props);        
    }

    renderImageOrVideoForCard(entry){
        console.log("Getting entry");
        console.log(entry);
        console.log("/ Getting entry");
        if(entry.image) {
            console.log("RETURNING IMAGE");
            return(
                <CardMedia
                component="img"
                alt={entry.description}
                image={entry.image}
                title={entry.name}
                /> 
            )
        } else if(entry.video) {
            console.log("RETURNING VIDEO");
            return(
                <ReactPlayer 
                    width={'100%'}
                    height={'100%'}
                    style={{
                        boxShadow: "4px 5px 15px -3px rgba(0,0,0,0.64)"
                    }}
                    controls={true}        
                    url={entry.video} 
                    />
            )
        }
    }
    
    getFirstEntry(){
        if(this.props.entries.slice(0,1)){
            console.log(this.props);
            var entryOne = this.props.entries.slice(0, 1);
            var firstEntry = entryOne.map((entry) => 
                <div className="d-flex justify-content-center">
                    <Card style={{maxWidth: '800px'}}>                        
                        <CardActionArea>                            
                            {this.renderImageOrVideoForCard(entry)}
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {entry.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {entry.description}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>                                        
                </div>
            )
            return firstEntry;
        }                
    }

    formatDate(string){
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    render(){        
        return (
        <div>
            <div style={{paddingTop:"10px", margin: "50px 0px 50px 0px"}}>
                {this.getFirstEntry()}
            </div>
            <Timeline align="alternate">
                {this.props.entries.slice(1).map((entry) =>                    
                        <TimelineItem>
                            <TimelineOppositeContent style={{margin: "50px 0px"}}>
                                <Typography variant="body2" color="textSecondary">
                                    {this.formatDate(entry.eventAt)}
                                </Typography>
                                <Typography>{entry.description}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot style={{backgroundColor: '#426d49'}}>
                                    <ChildFriendlyIcon style={{color: "white"}} />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{margin: "50px 0px 50px 0px"}} data-aos="fade-down">                            
                                <Entry key={entry.id} title={entry.name} description={""} image={entry.image} video={entry.video} icon={entry.icon}/>                             
                            </TimelineContent>
                        </TimelineItem>                    
                    )                    
                }                
            </Timeline>     
        </div>
        );
    }
}

export default Row;