import React from 'react'
import ReactPlayer from 'react-player'
import {
  Box,
  Card,
  Image,
  Heading,
  Text
} from 'rebass'

class Entry extends React.Component{
  constructor(props) {
      super(props);
      console.log(this.props);      
  }

  renderVideoOrImage(first){
    var widthSize = "100%";

    if(first){
      widthSize = "100%";
    }

    if(this.props.image) return (
        <Image src={this.props.image}
          width={widthSize}
          sx={{
            maxWidth: '300px',
            width: '100%',
            maxHeight: '400px',
            borderRadius: '8px',
            boxShadow: "4px 5px 15px -3px rgba(0,0,0,0.64)"
          }}          
        />
      )
    if(this.props.video) return (
      <ReactPlayer
        height={'400px'} 
        width={'100%'}
        style={{
          boxShadow: "4px 5px 15px -3px rgba(0,0,0,0.64)"
        }}
        controls={true}        
        url={this.props.video} 
      />
    ) 

    if(this.props.icon) return (
      <Image src={this.props.icon}
          width={widthSize}
          sx={{
            maxWidth: '100px',
            width: '100%',
            maxHeight: '100px',            
          }}          
        />
    )
  }

  render(){
    return(
      <div className = {this.props.className}>
      <Box>
        <Card          
          sx={{     
            maxHeight: '400px',                 
            p: 1,
            borderRadius: 2,            
          }}>          
          {this.renderVideoOrImage(this.props.first)}          
        </Card>
      </Box>
    </div>  

      
    );
  }
}

export default Entry;