/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:2db5dd39-1478-4ffc-a319-d48243330087",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_we2CBscJd",
    "aws_user_pools_web_client_id": "2ofjraftqnruejqhuqu32janlr",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lcdu562rrfetvip3q5j5vi5efu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2l7d2szlarfw3infm6mael6ou4",
    "aws_user_files_s3_bucket": "timelineapp48adc531b28946eaaa11a452c230893453001-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
